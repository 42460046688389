var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "widget" }, [
    _c(
      "section",
      { staticClass: "widget__header" },
      [
        _c("h2", {
          staticClass: "widget__title",
          domProps: { textContent: _vm._s(_vm.title) }
        }),
        _vm._v(" "),
        _vm._t("appendHeader")
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "widget__body" },
      [
        _vm.isPlaceholderVisible
          ? _c("WidgetNoDataPlaceholder")
          : _vm._t("body")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }