import { render, staticRenderFns } from "./UploadFileListLoadingElement.vue?vue&type=template&id=5f8da85e&scoped=true&"
import script from "./UploadFileListLoadingElement.vue?vue&type=script&lang=js&"
export * from "./UploadFileListLoadingElement.vue?vue&type=script&lang=js&"
import style0 from "./UploadFileListLoadingElement.vue?vue&type=style&index=0&id=5f8da85e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f8da85e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ergonode/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f8da85e')) {
      api.createRecord('5f8da85e', component.options)
    } else {
      api.reload('5f8da85e', component.options)
    }
    module.hot.accept("./UploadFileListLoadingElement.vue?vue&type=template&id=5f8da85e&scoped=true&", function () {
      api.rerender('5f8da85e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/UploadFileList/UploadFileListLoadingElement.vue"
export default component.exports