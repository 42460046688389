var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "upload-file-list-loading-element" }, [
    _c(
      "div",
      { staticClass: "upload-file-list-loading-element__uploader" },
      [
        _vm.isUploading
          ? [
              _c("IconSpinner", {
                attrs: { color: _vm.graphiteColor, width: "16", height: "16" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "info-label" }, [_vm._v("Uploading")])
            ]
          : [
              _c("IconCheck", { attrs: { "fill-color": _vm.greenColor } }),
              _vm._v(" "),
              _c("span", { staticClass: "info-label" }, [_vm._v("Finished")]),
              _vm._v(" "),
              _vm.errorsNumber
                ? _c("span", {
                    staticClass: "info-error-label",
                    domProps: { textContent: _vm._s(_vm.errorMessage) }
                  })
                : _vm._e()
            ]
      ],
      2
    ),
    _vm._v(" "),
    _c("span", {
      class: _vm.errorsNumber ? "info-error-label" : "info-label",
      domProps: { textContent: _vm._s(_vm.numberOfUploadedElements) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }