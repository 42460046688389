var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { class: _vm.classes },
    [
      _c(
        "div",
        { staticClass: "horizontal-wrapper" },
        [
          _c("div", { staticClass: "vertical-wrapper" }, [
            _c("span", {
              staticClass: "upload-file-list-element__file-name",
              domProps: { textContent: _vm._s(_vm.file.name) }
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "upload-file-list-element__file-size",
              domProps: { textContent: _vm._s(_vm.fileProgress) }
            })
          ]),
          _vm._v(" "),
          _vm.isError
            ? _c("IconButton", {
                attrs: { size: _vm.smallSize, theme: _vm.secondaryPlainTheme },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "icon",
                      fn: function() {
                        return [
                          _c("IconError", {
                            attrs: { "fill-color": _vm.redColor }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2885868653
                )
              })
            : [
                _c("IconButton", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isUploading,
                      expression: "isUploading"
                    }
                  ],
                  attrs: {
                    size: _vm.smallSize,
                    theme: _vm.secondaryPlainTheme
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.onCancelUploading.apply(null, arguments)
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function() {
                        return [_c("IconFilledClose")]
                      },
                      proxy: true
                    }
                  ])
                }),
                _vm._v(" "),
                _c("IconButton", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isUploading,
                      expression: "!isUploading"
                    }
                  ],
                  attrs: {
                    size: _vm.smallSize,
                    theme: _vm.secondaryPlainTheme
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function() {
                        return [
                          _c("IconCheck", {
                            attrs: { "fill-color": _vm.greenColor }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c("ProgressBar", {
        attrs: { color: _vm.progressColor, value: _vm.file.progressValue }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }