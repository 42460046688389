var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputUUIDProvider", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var uuid = ref.uuid
          return [
            _c("InputSolidStyle", {
              attrs: {
                size: _vm.size,
                height: _vm.height,
                disabled: _vm.disabled,
                "details-label": _vm.informationLabel
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function() {
                      return [
                        _c(
                          "InputController",
                          [
                            !_vm.value
                              ? _c("input", {
                                  ref: "input",
                                  staticClass: "upload-file",
                                  attrs: {
                                    id: uuid,
                                    type: "file",
                                    accept: _vm.acceptFiles,
                                    disabled: _vm.disabled
                                  },
                                  on: { input: _vm.onUpload }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "InputImageController",
                              [
                                !_vm.value
                                  ? _c("InputUploadPlaceholder")
                                  : _vm.requestPending
                                  ? _c("InputUploadSpinner")
                                  : _c(
                                      "div",
                                      { staticClass: "upload-file__content" },
                                      [_vm._t("file")],
                                      2
                                    ),
                                _vm._v(" "),
                                !_vm.disabled && _vm.value
                                  ? _c("Fab", {
                                      attrs: {
                                        floating: {
                                          top: "4px",
                                          right: "4px",
                                          backgroundColor: _vm.whiteColor
                                        },
                                        theme: _vm.destructiveTheme
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.onRemove.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "icon",
                                            fn: function(ref) {
                                              var color = ref.color
                                              return [
                                                _c("IconDelete", {
                                                  attrs: { "fill-color": color }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.label
                              ? _c("InputLabel", {
                                  attrs: {
                                    for: uuid,
                                    required: _vm.required,
                                    floating: true,
                                    disabled: _vm.disabled,
                                    label: _vm.label,
                                    error: _vm.isError
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }